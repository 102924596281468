import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationService } from "app/core/services/navigation.service";
import { LanguageService } from "app/core/services/language.service";
import { LayoutService } from "app/core/services/layout.service";
import { UserService } from "app/core/services/user.service";

import { Subscription } from 'rxjs';
import { PermissionService } from 'app/core/services/permission.service';
import { ConfigService } from 'app/core/services/config.service';

import { PORTALTYPE } from "../../../core/helpers/enum.helper";
import { decodedToken } from '../../../core/helpers/token.helper';
import { get } from "lodash";
import { ParentService } from 'app/core/services/parent.service';
import { CurrentUserService } from 'app/core/services/current-user.service';
import { Router } from '@angular/router';
// import { SelectStudentComponent } from 'app/modules/parent/dashboard/select-student/select-student.component';
import { MatDialog } from '@angular/material/dialog';
import { array } from '@amcharts/amcharts4/core';
import { TeacherService } from 'app/core/services/teacher.service';
// import { SelectBatchComponent } from 'app/modules/teacher/dashboard/select-batch/select-batch.component';
import { CommonService } from 'app/modules/common/services/common.service';
import { BatchService } from 'app/modules/hospital/academic/batch/services/batch.service';
import { Batch } from 'app/shared/models/batch/batch.model';
import { MessagingService } from 'app/core/services/messaging.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ["./header-top.component.scss"],
})

export class HeaderTopComponent implements OnInit, OnDestroy {

  layoutConf: any;
  currentLang: '';
  menuItems: any;
  menuItemSub: Subscription;
  availableLangs = [];
  @Input() notificPanel;
  @Input() messagePanel;
  fontFamily: string;
  portalType: string = '';
  userType: string = '';
  checkType: any = PORTALTYPE;
  users = [];
  currentUser = '';
  userProfile;
  teacherProfile: any;
  academicId: string;
  batches: Batch[];
  batchId = '';
  queueCount = 0;

  constructor(
    public navService: NavigationService,
    private renderer: Renderer2,
    private layout: LayoutService,
    private langService: LanguageService,
    private userService: UserService,
    private parentService: ParentService,
    private router: Router,
    public permission: PermissionService,
    private configService: ConfigService,
    public dialog: MatDialog,
    private teacherService: TeacherService,
    private currentUserService: CurrentUserService,
    private commonService: CommonService,
    private batchService: BatchService,
    private messagingService: MessagingService,
    private generateRouteService: GenerateRouteService

  ) {
    this.autoInit();
    this.getQueueValue();
    const token = window.localStorage.getItem('TGJWTTOKEN');
    this.messagingService.requestPermission(token);
    this.messagingService.receiveMessage();
    // node --max_old_space_size=8192 node_modules/@angular/cli/bin/ng serve --o --watch --host queenmira.petals.temp
    this.availableLangs = this.langService.languages;
  }
  /** QUEUE BADGE COUNT */

  getQueueValue() {
    this.generateRouteService.queueCount
      .subscribe(res => {
        this.queueCount = res >= 0 ? res : 0;
      });
  }

  autoInit() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
    this.userType = get(token, 'user_type') ?? '';
    // if (this.portalType == PORTALTYPE.PARENT) {
    //   this.users = this.currentUserService.users;
    //   let checkStudent = this.checkUserLength(this.users);
    //   if (checkStudent) {
    //     this.currentUser = checkStudent;
    //     this.userProfile = this.getStudentPicture(this.users, this.currentUser);
    //   } else {
    //     this.currentUser = '';
    //     this.openpopup();
    //   }
    //   this.currentUserService.currentStudent.subscribe(res => {
    //     if (res) {
    //       this.currentUser = this.checkUserLength(this.users);
    //       this.userProfile = this.getStudentPicture(this.users, this.currentUser);
    //     }
    //   })
    // } else if (this.portalType == PORTALTYPE.TEACHER) {
    //   this.teacherProfile = get(token, 'employee_photo') ?? '';
    //   this.commonService.getCurrentAcademicYear().subscribe((res) => {
    //     if (res.keyword == 'success') {
    //       this.academicId = res.data[0]?.academic_year_id ?? '';
    //       this.teacherService.setAcademicId(this.academicId);
    //       this.teacherService.setAcademicName(res.data[0]?.academic_name);
    //       this.batchService.getListByAcademic(res.data[0]?.academic_year_id).subscribe((el) => {
    //         if (res.keyword == 'success') {
    //           this.batches = el.batches;
    //           if (this.checkIdsForTeacher() == false) {
    //             this.openPopupForbatch();
    //           } else {
    //             this.batches ? this.batchId = localStorage.getItem('BATCHID') : '';
    //           }
    //         }
    //       })
    //     }
    //   });
    // }
  }


  setBatchIdForTeacher(batchId) {
    this.teacherService.setBatchId(batchId);
    var batchDTO = this.batches.find((x) => x.batchId == batchId);
    this.teacherService.setbatchName(batchDTO?.batchName);
  }


  checkIdsForTeacher(): boolean {
    var batchId = window.localStorage.getItem('BATCHID') ?? '';
    return batchId == '' ? false : true;
  }

  getStudentPicture(arr, id) {
    return arr.find(el => el.student_id === id);
  }

  // openpopup() {
  //   const dialogRef = this.dialog.open(SelectStudentComponent, {
  //     width: "40%",
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //   });
  // }

  // openPopupForbatch() {
  //   const dialogRef = this.dialog.open(SelectBatchComponent, {
  //     width: "40%",
  //     data: this.batches,
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe((batchId) => {
  //     this.batchId = window.localStorage.getItem('BATCHID')
  //   });
  // }

  checkUserLength(userArray: Array<[]>) {
    return this.currentUserService.getCurrentStudent() || '';
    // if(userArray.length === 1) {
    //   this.currentUserService.setUser(userArray[0]['student_id']);
    //   return userArray[0]['student_id'];
    // } else if(userArray.length === 0) {
    //   this.currentUserService.deleteUser();
    //   return '';
    // } else {
    //   return this.currentUserService.getCurrentStudent() || '';
    // }
  }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 12
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }

  ngOnDestroy() {
    this.currentUserService.unSubStud();
    this.menuItemSub.unsubscribe()
  }

  setUser(student_id) {
    this.currentUserService.setUser(student_id);
  }

  setLang() {
    this.langService.setLang(this.currentLang);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleMessage() {
    this.messagePanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  logout(portalType) {
    this.messagingService.deleteToken();
    this.messagingService.sendFCMToken(null);
    if (portalType == PORTALTYPE.CUSTOMER) {
      this.userService.logout();
    } else if (portalType == PORTALTYPE.HOSPITAL) {
      this.userService.logout();
    } else if (portalType == PORTALTYPE.TEACHER) {
      this.teacherService.logout();
    } else if (portalType == PORTALTYPE.PARENT) {
      this.parentService.logout();
    } else {
      this.userService.logout();
    }
  }

  viewMyProfile() {
    this.router.navigate(['parent/student/profile/view']);
  }
  viewTecherMyProfile() {
    this.router.navigate(['teacher/my-profile/myprofile']);
  }
}
